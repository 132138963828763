<template>
    <div class="posPrintSet">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['save','goBack']"
                @saveClick="saveData()"
                @goBackClick="$router.back()"/>
        </div>
        <div class="content-box">
            <div class="panel-box">
                <div class="panel-heading">打印机分配——{{siteName}}
                    <div class="tr-li">
                        <el-checkbox v-model="Is_ServicePrint" label="本站点不支持直接打印，按后台打印服务打印" size="large"></el-checkbox>
                    </div>
                </div>
                <div class="panel-body">
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_KKPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印客看单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="KK_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in KK_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="KK_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="KK_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_XFPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印消费单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="XF_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in XF_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="XF_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="XF_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_JZPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印结账单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="JZ_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in JZ_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="JZ_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="JZ_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_JBPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印结班单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="JB_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in JB_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="JB_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="JB_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_RJPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印日结单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="RJ_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in RJ_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="RJ_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="RJ_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_KDSPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印KDS核销单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="KDS_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in KDS_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="KDS_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="KDS_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_PCPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印配菜单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="PC_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in PC_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="PC_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="PC_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_BKPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印办卡" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="BK_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in BK_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="BK_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="BK_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_CZPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印卡充值" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="CZ_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in CZ_printModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="CZ_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="CZ_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_CFZDPrint" class="mb-2" :inline-prompt="true" active-text="本站点快餐结账时，直打厨房单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">出单类型：</div>
                            <div class="from-input">
                                <el-select v-model="CFZD_DetailInfo.Print_TemplateID" multiple collapse-tags placeholder="请选择出单类型">
                                    <el-option :value="item.Eat_IssueAutoID" :label="item.Eat_IssueName" v-for="item in kitIssuingTypes" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="CFZD_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="CFZD_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_ARClearPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印AR清账单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="ArClear_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in AR_ClarePrintModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="ArClear_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="ArClear_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_ARClearRecharge" class="mb-2" :inline-prompt="true" active-text="本站点打印AR预付充值单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="ArRecharge_DetailInfo.Print_TemplateID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in CZ_RechargePrintModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="ArRecharge_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="ArRecharge_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4">
                            <el-switch v-model="IS_YDXFBMPrint" class="mb-2" :inline-prompt="true" active-text="本站点打印预排菜单" inactive-text=""></el-switch>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印模板：</div>
                            <div class="from-input">
                                <el-select v-model="YDXFBM_DetailInfo.Print_ModelID" placeholder="请选择打印模板">
                                    <el-option :value="item.PrintModel_AutoID" :label="item.PrintModel_Name" v-for="item in YDXFBMPrintModels" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">选择打印机：</div>
                            <div class="from-input">
                                <el-select v-model="YDXFBM_DetailInfo.Printer_ID" placeholder="请选择打印机">
                                    <el-option :value="item.Printer_AutoID" :label="item.Printer_Name" v-for="item in printers" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="td-4">
                            <div class="lable-txt">打印份数：</div>
                            <div class="from-input"><inputPattern v-model="YDXFBM_DetailInfo.Print_Num" type="number" pattern="number" :min="1" :dplaces="0"></inputPattern></div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-4"></div>
                        <div class="td-4">
                            <div class="lable-txt">出单类型：</div>
                            <div class="from-input">
                                <el-select v-model="YDXFBM_DetailInfo.Print_TemplateID" multiple collapse-tags placeholder="请选择出单类型">
                                    <el-option :value="item.Eat_IssueAutoID" :label="item.Eat_IssueName" v-for="item in kitIssuingTypes" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
</template>

<script>
/**站点打印机分配 */
export default {
    name:"posPrintSet",
    data(){
        return {
            //设备名称
            siteName:"",
            //主键
            Assigned_ID:'',
            //是否不直接打印，由服务打印
            Is_ServicePrint:false,
            IS_KKPrint:false,
            /**客看单 */
            KK_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_XFPrint:false,
            /**消费单 */
            XF_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_JZPrint:false,
            /**结账单 */
            JZ_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'', 
                Print_Num:1
            },
            IS_JBPrint:false,
            /**结班单 */
            JB_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_RJPrint:false,
            /**日结单 */
            RJ_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_KDSPrint:false,
            /**本站点打印KDS核销单 */
            KDS_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_PCPrint:false,
            /**配菜单 */
            PC_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_BKPrint:false,
            /**本站点打印办卡 */
            BK_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_CZPrint:false,
            /**本站点打印卡充值 */
            CZ_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_CFZDPrint:false,
            /**本站点快餐结账时，直打厨房单 */
            CFZD_DetailInfo:{
                Print_TemplateID:[],
                Printer_ID:'',
                Print_Num:1
            },
            IS_ARClearPrint:false,
            ArClear_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            IS_ARClearRecharge:false,
            ArRecharge_DetailInfo:{
                Print_TemplateID:'',
                Printer_ID:'',
                Print_Num:1
            },
            /**本站点打印预排菜单 */
            IS_YDXFBMPrint:false,
            YDXFBM_DetailInfo:{
                Print_TemplateID:[],
                Print_ModelID:'',
                Printer_ID:'',
                Print_Num:1
            },
            printers:[],
            printModels:[],
            //厨房出单类型数据
            kitIssuingTypes:[],
        };
    },
    computed:{
        //客看单 模板 
        KK_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==8);
        },
        //消费单 模板
        XF_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==1);
        },
        //结账单 模板
        JZ_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==2);
        },
        //结班单 模板
        JB_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==9);
        },
        //日结单 模板
        RJ_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==10);
        },
        //KDS核销单 模板
        KDS_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==801);
        },
        //配菜单 模板
        PC_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==11);
        },
        //办卡 模板
        BK_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==51);
        },
        //充值 模板
        CZ_printModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==52);
        },
        //AR清账打印模板
        AR_ClarePrintModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==9011);
        },
        //AR预付款打印模板
        CZ_RechargePrintModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==9012);
        },
        //本站点打印预排菜单 打印模板
        YDXFBMPrintModels(){
            return this.printModels.filter(t=>t.PrintModel_Type==801);
        },
    },
    mounted(){
        this.$emit("navIndex",4);
        this.loadBaseData();
        let userInfo= this.$auth.getUserInfo();
        if(userInfo){
            this.siteName=userInfo.Site_Name;
        }
        this.loadPosPrintModuleNote();
    },
    methods:{
        loadBaseData(){
            this.$cacheData.Printers().then((d)=>{
                this.printers=d;
            }).catch(e=>{
                console.log('打印机数据获取失败e:'+e)
            })
            this.$cacheData.PrintModels().then(d=>{
                this.printModels=d
            }).catch(e=>{
                console.log('打印模板数据获取失败e:'+e)
            });
            this.$cacheData.KitIssuingTypes().then((d)=>{
                this.kitIssuingTypes=d;
                console.log(d)
            }).catch(e=>{
                console.log('厨房出单类型数据获取失败e:'+e)
            })
        },
        //站点打印机分配数据
        loadPosPrintModuleNote(){
            const loading = this.$loading({
                text: "加载站点打印机分配数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetSTKEquipmentPrintInfo",{ }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    let info=d.ResponseBody||{};
                    if(info){
                        this.Assigned_ID=info.Assigned_ID;
                        this.Is_ServicePrint=info.Is_ServicePrint;
                        if(info.KK_DetailInfo){
                            this.IS_KKPrint=true;
                            this.KK_DetailInfo=info.KK_DetailInfo;
                        }
                        if(info.XF_DetailInfo){
                            this.IS_XFPrint=true;
                            this.XF_DetailInfo=info.XF_DetailInfo;
                        }
                        if(info.JZ_DetailInfo){
                            this.IS_JZPrint=true;
                            this.JZ_DetailInfo=info.JZ_DetailInfo;
                        }
                        if(info.JB_DetailInfo){
                            this.IS_JBPrint=true;
                            this.JB_DetailInfo=info.JB_DetailInfo;
                        }
                        if(info.RJ_DetailInfo){
                            this.IS_RJPrint=true;
                            this.RJ_DetailInfo=info.RJ_DetailInfo;
                        }
                        if(info.KDS_DetailInfo){
                            this.IS_KDSPrint=true;
                            this.KDS_DetailInfo = info.KDS_DetailInfo;
                        }
                        if(info.PC_DetailInfo){
                            this.IS_PCPrint=true;
                            this.PC_DetailInfo = info.PC_DetailInfo;
                        }
                        if(info.BK_DetailInfo){
                            this.IS_BKPrint=true;
                            this.BK_DetailInfo = info.BK_DetailInfo;
                        }
                        if(info.CZ_DetailInfo){
                            this.IS_CZPrint=true;
                            this.CZ_DetailInfo = info.CZ_DetailInfo;
                        }
                        if(info.CFZD_DetailInfo){
                            this.IS_CFZDPrint=true;
                            this.CFZD_DetailInfo = info.CFZD_DetailInfo;
                        }
                        if(info.ArClear_DetailInfo){
                            this.IS_ARClearPrint=true;
                            this.ArClear_DetailInfo = info.ArClear_DetailInfo;
                        }
                        if(info.ArRecharge_DetailInfo){
                            this.IS_ARClearRecharge=true;
                            this.ArRecharge_DetailInfo = info.ArRecharge_DetailInfo;
                        }
                        if(info.YDXFBM_DetailInfo){//本站点打印预排菜单
                            this.IS_YDXFBMPrint=true;
                            this.YDXFBM_DetailInfo = info.YDXFBM_DetailInfo;
                        }
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('未找到站点打印机分配数据：'+e);
                console.log('未找到站点打印机分配数据：'+e);
            })
        },
        /**保存数据 */
        saveData(){
            
            let info={
                Assigned_ID:this.Assigned_ID,
                Is_ServicePrint:this.Is_ServicePrint
            }
            if(this.IS_KKPrint){
                info.KK_DetailInfo=this.KK_DetailInfo;
                if(!this.KK_DetailInfo.Print_TemplateID){
                    this.$message.warning('客看单打印模板未设置！');
                    return;
                }
                if(!this.KK_DetailInfo.Printer_ID){
                    this.$message.warning('客看单打印机未设置！');
                    return;
                }
                if(!(this.KK_DetailInfo.Print_Num>0)){
                    this.$message.warning('客看单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_XFPrint){
                info.XF_DetailInfo=this.XF_DetailInfo;
                if(!this.XF_DetailInfo.Print_TemplateID){
                    this.$message.warning('消费单打印模板未设置！');
                    return;
                }
                if(!this.XF_DetailInfo.Printer_ID){
                    this.$message.warning('消费单打印机未设置！');
                    return;
                }
                if(!(this.XF_DetailInfo.Print_Num>0)){
                    this.$message.warning('消费单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_JZPrint){
                info.JZ_DetailInfo=this.JZ_DetailInfo;
                if(!this.JZ_DetailInfo.Print_TemplateID){
                    this.$message.warning('结账单打印模板未设置！');
                    return;
                }
                if(!this.JZ_DetailInfo.Printer_ID){
                    this.$message.warning('结账单打印机未设置！');
                    return;
                }
                if(!(this.JZ_DetailInfo.Print_Num>0)){
                    this.$message.warning('结账单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_JBPrint){
                info.JB_DetailInfo=this.JB_DetailInfo;
                if(!this.JB_DetailInfo.Print_TemplateID){
                    this.$message.warning('结班单打印模板未设置！');
                    return;
                }
                if(!this.JB_DetailInfo.Printer_ID){
                    this.$message.warning('结班单打印机未设置！');
                    return;
                }
                if(!(this.JB_DetailInfo.Print_Num>0)){
                    this.$message.warning('结班单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_RJPrint){
                info.RJ_DetailInfo=this.RJ_DetailInfo;
                if(!this.RJ_DetailInfo.Print_TemplateID){
                    this.$message.warning('日结单打印模板未设置！');
                    return;
                }
                if(!this.RJ_DetailInfo.Printer_ID){
                    this.$message.warning('日结单打印机未设置！');
                    return;
                }
                if(!(this.RJ_DetailInfo.Print_Num>0)){
                    this.$message.warning('日结单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_KDSPrint){
                info.KDS_DetailInfo = this.KDS_DetailInfo;
                if(!this.KDS_DetailInfo.Print_TemplateID){
                    this.$message.warning('KDS核销单打印模板未设置！');
                    return;
                }
                if(!this.KDS_DetailInfo.Printer_ID){
                    this.$message.warning('KDS核销单打印机未设置！');
                    return;
                }
                if(!(this.KDS_DetailInfo.Print_Num>0)){
                    this.$message.warning('KDS核销单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_PCPrint){
                info.PC_DetailInfo = this.PC_DetailInfo;
                if(!this.PC_DetailInfo.Print_TemplateID){
                    this.$message.warning('配菜单打印模板未设置！');
                    return;
                }
                if(!this.PC_DetailInfo.Printer_ID){
                    this.$message.warning('配菜单打印机未设置！');
                    return;
                }
                if(!(this.PC_DetailInfo.Print_Num>0)){
                    this.$message.warning('配菜单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_BKPrint){
                info.BK_DetailInfo = this.BK_DetailInfo;
                if(!this.BK_DetailInfo.Print_TemplateID){
                    this.$message.warning('办卡打印模板未设置！');
                    return;
                }
                if(!this.BK_DetailInfo.Printer_ID){
                    this.$message.warning('办卡打印机未设置！');
                    return;
                }
                if(!(this.BK_DetailInfo.Print_Num>0)){
                    this.$message.warning('办卡打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_CZPrint){
                info.CZ_DetailInfo = this.CZ_DetailInfo;
                if(!this.CZ_DetailInfo.Print_TemplateID){
                    this.$message.warning('充值打印模板未设置！');
                    return;
                }
                if(!this.CZ_DetailInfo.Printer_ID){
                    this.$message.warning('充值打印机未设置！');
                    return;
                }
                if(!(this.CZ_DetailInfo.Print_Num>0)){
                    this.$message.warning('充值打印份数必须大于0！');
                    return;
                }
            }

            if(this.IS_CFZDPrint){
                info.CFZD_DetailInfo = this.CFZD_DetailInfo;
                if(!this.CFZD_DetailInfo.Print_TemplateID?.length>0){
                    this.$message.warning('直打厨房单出单类型未设置！');
                    return;
                }
                if(!this.CFZD_DetailInfo.Printer_ID){
                    this.$message.warning('直打厨房单打印机未设置！');
                    return;
                }
                if(!(this.CFZD_DetailInfo.Print_Num>0)){
                    this.$message.warning('直打厨房单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_ARClearPrint){
                info.ArClear_DetailInfo = this.ArClear_DetailInfo;
                if(!this.ArClear_DetailInfo.Print_TemplateID?.length>0){
                    this.$message.warning('AR清账单出单打印模板未设置！');
                    return;
                }
                if(!this.ArClear_DetailInfo.Printer_ID){
                    this.$message.warning('AR清账单打印机未设置！');
                    return;
                }
                if(!(this.ArClear_DetailInfo.Print_Num>0)){
                    this.$message.warning('AR清账单打印份数必须大于0！');
                    return;
                }
            }
            if(this.IS_ARClearRecharge){
                info.ArRecharge_DetailInfo = this.ArRecharge_DetailInfo;
                if(!this.ArRecharge_DetailInfo.Print_TemplateID?.length>0){
                    this.$message.warning('AR预付充值单打印模板未设置！');
                    return;
                }
                if(!this.ArRecharge_DetailInfo.Printer_ID){
                    this.$message.warning('AR预付充值单打印机未设置！');
                    return;
                }
                if(!(this.ArRecharge_DetailInfo.Print_Num>0)){
                    this.$message.warning('AR预付充值单打印份数必须大于0！');
                    return;
                }
            }

            if(this.IS_YDXFBMPrint){//本站点打印预排菜单
                info.YDXFBM_DetailInfo = this.YDXFBM_DetailInfo;
                if(!this.YDXFBM_DetailInfo.Print_TemplateID?.length>0){
                    this.$message.warning('预排菜单出单类型未设置！');
                    return;
                }
                if(!this.YDXFBM_DetailInfo.Print_ModelID){
                    this.$message.warning('预排菜单打印模板未设置！');
                    return;
                }
                if(!this.YDXFBM_DetailInfo.Printer_ID){
                    this.$message.warning('预排菜单打印机未设置！');
                    return;
                }
                if(!(this.YDXFBM_DetailInfo.Print_Num>0)){
                    this.$message.warning('预排菜单打印份数必须大于0！');
                    return;
                }
            }
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.SaveEquipmentPrintInfo",{
                EquipmentPrintInfo:info,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    if(!this.IS_KKPrint){
                        this.KK_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_XFPrint){
                        this.XF_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_JZPrint){
                        this.JZ_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_JBPrint){
                        this.JB_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_RJPrint){
                        this.RJ_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_KDSPrint){
                        this.KDS_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_PCPrint){
                        this.PC_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_BKPrint){
                        this.BK_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_CZPrint){
                        this.CZ_DetailInfo={
                            Print_TemplateID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_CFZDPrint){
                        this.CFZD_DetailInfo={
                            Print_TemplateID:[],
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_ARClearPrint){
                        this.ArClear_DetailInfo={
                            Print_TemplateID:[],
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                    if(!this.IS_ARClearRecharge){
                        this.ArRecharge_DetailInfo={
                            Print_TemplateID:[],
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }

                    if(!this.IS_YDXFBMPrint){
                        this.YDXFBM_DetailInfo={
                            Print_TemplateID:[],
                            Print_ModelID:'',
                            Printer_ID:'',
                            Print_Num:1
                        }
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('站点打印机分配数据保存失败：'+e);
                console.log('站点打印机分配数据保存失败：'+e);
            })
        }
    }

}
</script>

<style lang="scss">
@import './posPrintSet.scss'
</style>